/* App.css */

.blog-container {
    display: flex;
    justify-content: center;
    margin: 20px 40px; /* Adds margin around the entire blog layout for centering */
  }

  .blog-header-text {
    font-size: 2.5em;
    font-weight: bold;
    padding: 20px;
    color: black;
  }
  
  .main-content {
    width: 70%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; /* Ensures spacing between columns */
    padding-right: 20px; /* Adds padding between the main content and the sidebar */
  }
  
  .video-post {
    flex: 1 1 45%; /* Each video post takes up about 45% of the .main-content width */
    margin: 10px;
    background-color: var(--bg-section); /* Lighter gray background */
    padding: 20px;
    box-sizing: border-box; /* Includes padding in the width calculation */
  }
  
  .latest-blogs {
    width: 30%;
    padding: 20px;
    background-color: var(--bg-main); /* Main gray background */
    box-sizing: border-box; /* Includes padding in the width calculation */
  }
  
  .latest-blog-post {
    margin-bottom: 20px;
  }
  
  @media (max-width: 768px) {
    .blog-container {
      flex-direction: column;
      margin: 20px;
    }
  
    .main-content, .latest-blogs {
      width: 100%;
      padding: 0;
    }
  
    .video-post {
      flex: 1 1 100%; /* Full width on smaller screens */
    }
  }

  .blog-header {
    position: relative;
    text-align: center;
    color: white; /* White text color for better contrast */
    background-image: url('../images/blog_notes.jpg'); /* Path to the background image */
    background-size: cover; /* Cover the entire div */
    background-position: center; /* Center the background image */
    height: 200px; /* Set the height of the image container */
    display: flex;
    justify-content: center;
    align-items: center;
  }