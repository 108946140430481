/* App.css */

/* Color scheme */
:root {
    --bg-main: #d3d5d6;
    --bg-section: #ebedee;
    --accent-color: #1578a6;
    --text-color: #ffffff;
  }
  
  body, html {
    background-color: var(--bg-main);
    color: var(--text-color);
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    width: 100%;
    overflow-x: hidden;
    max-width: 100vw;
  }

  /* App.css */

/* App.css */

.home-section {
    background-color: var(--bg-main);
    padding: 40px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .home-section2 {
    background-color: var(--bg-section);
    padding: 40px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  .column {
    flex: 0 1 45%; /* Adjusts each column to be narrower with a max-width of 45% */
    min-width: 250px; /* Minimum width for smaller devices */
    margin-left: 30px;
  }

  .extra-padding {
    padding-left: 80px;
    padding-right: 10px;
  }

  .extra-bottom-padding {
    padding-bottom: 100px;
  }
  
  .column-header {
    color: var(--accent-color);
    font-size: 1.5em; /* Smaller font for 'Welcome to' */
    text-align: left; /* Aligning text to the left */
  }
  
  .column-main-header {
    font-size: 2.5em; /* Larger font for 'The Home of Elliot Barnett' */
    margin-top: 0.5em;
    color: #000; /* Changing color to black */
    text-align: left; /* Aligning text to the left */
  }
  
  .column-text {
    font-size: 1.2em;
    color: #333; /* Darker text color for better readability */
    text-align: left; /* Aligning text to the left */
  }
  
  .quote {
    font-size: 1.2em;
    font-weight: bold;
    margin-top: 20px;
    text-align: left; /* Aligning text to the left */
    color: #000; /* Changing color to black */
  }

  .justify-signature {
    text-align: right;

  }
  
  @media (max-width: 768px) {
    .home-section {
      flex-direction: column;
    }

    .home-section2 {
      flex-direction: column;
    }
    .column {
      flex: 0 1 100%; /* Each column takes full width on small screens */
    }
  }
  
  
/* App.css */

/* App.css */

  
  .logo {
    float: left;
    height: 50px;
    margin-right: 20px; /* Add margin to the right of the logo */
  }

  .logo-header {
    float: left;
    height: 50px;
    margin-right: 20px; /* Add margin to the right of the logo */
    margin-left: 100px;
  }
  
  .header {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black;
    padding: 1rem 2rem;
    color: white;
    position: relative; /* Ensures header is positioned relative to other content */
}

.main-nav {
    display: flex;
    align-items: center;
    justify-content: right;
    padding: 1rem;
    width: 100%;
}

.nav {
    display: flex;
    justify-content: center;
    transition: transform 0.3s ease;
}

.nav a {
    color: white;
    text-decoration: none;
    margin: 0 10px;
    transition: color 0.3s;
}

.hamburger {
    display: none; /* Keeps hamburger hidden on larger screens */
    cursor: pointer;
    position: absolute; /* Ensures hamburger does not push other elements */
    right: 20px; /* Positions to the right */
    top: 20px; /* Positions near the top of the header */
}

.hamburger .line {
    width: 30px;
    height: 3px;
    background-color: white;
    margin: 5px 0;
    transition: all 0.3s;
}

@media (max-width: 768px) {
    .nav {
        position: absolute;
        width: 100%; /* Ensures nav occupies the header entirely */
        left: 0; /* Aligns nav to the left edge */
        top: 100%; /* Positions it right below the header */
        background-color: black;
        transform: translateX(-100%); /* Hides nav off-screen to the left */
        flex-direction: column; /* Stacks links vertically when visible */
    }

    .nav.open {
        transform: translateX(0); /* Brings the navigation into view */
    }

    .nav a {
        margin: 10px 0; /* Adds vertical spacing between links */
    }

    .hamburger {
        display: block; /* Only show hamburger on smaller screens */
    }
}

  

  /* App.css */

/* App.css */

.footer {
    background-color: #000000;
    color: var(--text-color);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    flex-wrap: wrap;
   width:100%;
  }
  
  .footer-col {
    flex: 1;
    padding: 10px;
    min-width: 150px;
  }
  
  .footer-col:first-child {
    display: flex;
    flex-direction: column;
    align-items: center; /* Centering content for the first column */
  }
  
  .footer-link {
    color: var(--text-color);
    text-decoration: none;
    margin: 5px 5px; /* Adding spacing around each link */
    display: inline-block; /* This will help in adding margin correctly */
  }
  
  @media (max-width: 768px) {
    .footer {
      flex-direction: column;
      align-items: center;
    }
  
    .footer-col {
      width: 100%;
      text-align: center;
      margin-bottom: 20px; /* Add margin to bottom for spacing in mobile view */
    }
  
    .footer-link {
      margin: 10px; /* Increase margin for better spacing in mobile view */
    }
  }
  
  /* App.css */

.community-section {
    background-color: var(--bg-section);
    padding: 20px 0; /* Add vertical padding only */
    margin: 0; /* No margin */
    width: 100%; /* Full width */
    text-align: center;
    box-sizing: border-box; /* Padding included in the width */
  }
  
  .community-text {
    font-size: 1.2em;
    font-weight: bold;
    color: #333; /* Darker text for readability */
    display: inline; /* Keeps text and button inline */
  }
  
  .register-button {
    background-color: var(--accent-color);
    color: white;
    border: none;
    padding: 10px 20px;
    margin-left: 10px;
    border-radius: 20px; /* Rounded edges */
    cursor: pointer;
    outline: none;
    transition: background-color 0.3s, color 0.3s; /* Smooth transition for hover effect */
  }
  
  .register-button:hover {
    background-color: white; /* Background turns white */
    color: var(--accent-color); /* Text turns to accent color */
  }

  .feature-title {
    font-size: 2.5em;
    margin-bottom: 20px;
    font-weight: bold;
    color: #333; /* Darker text for readability */
  }
  
  .feature-list {
    display: flex;
    justify-content: center;
    gap: 20px;
  }
  
  .feature-column {
    flex: 1;
    color: #333; /* Darker text for readability */
  }
  
  .feature-description {
    margin-top: 20px;
    font-size: 1.1em;
    color: #333; /* Darker text for readability */
    margin-left: 50px;
    margin-right: 50px;
  }

  .checkmark {
    color: var(--accent-color);
    margin-right: 0.5rem;
  }

  .testimonial-section {
    padding: 2rem 0;
  }
  
  .testimonial-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Three columns */
    grid-gap: 1rem;
    margin-top: 1rem;
  }

  .container, .container-work {
    width: 80%;
    margin: auto;
    max-width: 1200px; /* Adjust max width as necessary */
    padding: 0; /* Ensure no padding is affecting the content */
}

.testimonial-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1rem;
    justify-content: center; /* Center the grid items within the container */
    margin: 1rem 0; /* Uniform margin for top and bottom */
}

  
  .testimonial {
    background-color: #f9f9f9;
    padding: 1rem;
    border-radius: 15px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1); /* Softer shadow for a subtle 3D effect */
    border-top: 3px solid var(--accent-color);; /* Accent color border */
    transition: transform 0.3s ease-in-out;
  }
  
  .testimonial:hover {
    transform: translateY(-5px);
  }
  
  .testimonial-content {
    font-size: 1rem;
    line-height: 1.6;
    color: #333; /* Dark text for readability */
    text-align: justify;
  }
  
  .testimonial-author {
    text-align: right;
    font-style: italic;
    margin-top: 1rem;
    color: var(--accent-color); /* Using the theme color for the author's name */
  }
  
  u.underline {
    text-decoration: var(--accent-color) underline;
    text-decoration-thickness: 3px;
  }

  .work-section {
    background-color: var(--bg-section);
    padding: 2rem 0;
  }
  /*.container {
    width: 80%;
    margin: auto;
    max-width: 1200px; 
  }

  .container-work {
    width: 75%;
    margin: auto;
    max-width: 1200px; 
  }*/

  .column-container {
    display: flex;
    flex-direction: column;
    background-color: #f9f9f9;
    padding: 1rem;
    border-radius: 15px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1); /* Softer shadow for a subtle 3D effect */
    border-top: 3px solid var(--accent-color);; /* Accent color border */
    transition: transform 0.3s ease-in-out;
    margin-top: 20px;
  }

  .work-content {
    font-size: 1.2rem;
    font-weight: bold;
    color: #333; /* Dark text for readability */
    text-align: left;
  }

  .responsive-video {
    width: 100%;
    height: auto;
    aspect-ratio: 16 / 9; /* Ensures the video maintains a 16:9 aspect ratio */
  }

  /* App.css */

.workshop-header {
    position: relative;
    text-align: center;
    color: white; /* White text color for better contrast */
    background-image: url('../images/seminar.jpg'); /* Path to the background image */
    background-size: cover; /* Cover the entire div */
    background-position: center; /* Center the background image */
    height: 300px; /* Set the height of the image container */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .workshop-header-text {
    font-size: 1.4em;
    font-weight: bold;
    padding: 20px;
    color: black;
  }
  
  .paragraph-text {
    font-size: 1em;
    color: #333; /* Darker text color for better readability */
    text-align: left; /* Aligning text to the left */
  }

  .smaller-header {
    font-size: 1.5em; /* Larger font for 'The Home of Elliot Barnett' */
    margin-top: 0.5em;
    color: #000; /* Changing color to black */
    text-align: left; /* Aligning text to the left */
  }

  .smaller-header-center {
    font-size: 1.2em; /* Larger font for 'The Home of Elliot Barnett' */
    margin-top: 0.5em;
    color: #000; /* Changing color to black */
    text-align: center; /* Aligning text to the left */
  }

  .flex-column {
    display: flex;
    flex-direction: column;
    max-width: 50%;
    padding: 0 10px;
  }

  .footer-column {
    display: flex;
    flex-direction: column;
  }
  
  .footer-row {
    display: flex;
    flex-direction: row;
  }

  .research-section {
    background-color: #ffffff;
    margin-left: 70px;
    margin-right: 70px;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 30px;
  }

  .research-header {
    color: black;
    text-align: center;
    font-size: 1.5em;
  }
  
  .research-content {
    color: black;
    size: 1em;
    text-align: left;
  }

  